<template>
  <div class="app-container">
    <el-table
      :data="tableData"
      style="width: 100%"
      v-loading="listLoading"
      border
      fit
      highlight-current-row
      class="tableList3"
    >
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column
        v-if="this.$route.query.id"
        prop="user_entity.id"
        label="用户ID"
        width="150px"
      ></el-table-column>
      <el-table-column
        v-if="this.$route.query.id"
        prop="user_entity.username"
        label="用户名"
        width="180px"
      ></el-table-column>
      <el-table-column prop="points_value" label="积分变动" width="150px">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.points_value > 0" type="success">
            {{ scope.row.points_value }}
          </el-tag>
          <el-tag v-else-if="scope.row.points_value < 0" type="warning">
            {{ scope.row.points_value }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        v-if="this.$route.query.id"
        prop="operator_entity.username"
        label="操作者"
        width="150px"
      ></el-table-column>
      <el-table-column prop="details" label="详述"></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        v-if="this.$route.query.id"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="变动时间"
        width="300px"
      ></el-table-column>
    </el-table>

    <div class="block" style="marginTop:10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-sizes="[pageSize, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getPointsMenu } from '@/api/points'
export default {
  data() {
    return {
      tableData: [],
      listLoading: true,
      pageIndex: 1,
      pageSize: 15,
      total: 0
    }
  },
  created() {
    this.getpointsMenu()
  },
  methods: {
    // 获取用户积分详情
    getpointsMenu() {
      this.listLoading = true
      // 管理员获取用户积分记录
      if (this.$route.query.id) {
        getPointsMenu({
          user: this.$route.query.id,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }).then(resp => {
          this.tableData = resp.items
          this.total = resp.all_count
        })
      } else {
        // 用户获取积分记录
        getPointsMenu({
          user: this.$store.state.user.userInfo.id,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }).then(resp => {
          this.tableData = resp.items
          this.total = resp.all_count
        })
      }
      this.listLoading = false
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.pageSize = val
      this.getpointsMenu()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.pageIndex = val
      this.getpointsMenu()
    }
  }
}
</script>

<style scoped></style>
