import { render, staticRenderFns } from "./pointsMenu.vue?vue&type=template&id=616e4405&scoped=true&"
import script from "./pointsMenu.vue?vue&type=script&lang=js&"
export * from "./pointsMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "616e4405",
  null
  
)

export default component.exports